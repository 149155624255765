import { SupportedLocale } from '../../locale/localizationContext';

export type GetCompanyLeaderboardPositionResponse = {
  items: MyLeaderboardPosition;
};

export type GetCompanyLeaderboardPositionRequest = {
  companyId: string;
  month: string;
};

export type MyLeaderboardPosition = {
  companyId: string;
  userId: string;
  month: string;
  points: number;
  position: number;
};

export type GetCompanyLeaderboardResponse = {
  items: LeaderboardElement[];
};

export type GetCompanyLeaderboardRequest = {
  companyId: string;
  month: string;
};

export type LeaderboardElement = {
  companyId: string;
  updatedAt: string;
  userId: string;
  points: number;
  createdAt: string;
  user: {
    username: string;
  };
  sk: string;
  yearMonth: string;
  pk: string;
};

export enum BadgeType {
  Tree = 'Treemmy',
}

export enum BadgeImage {
  NoTree = 'no_tree.png',
  MiniTree = 'mini_tree.png',
  SmallTree = 'small_tree.png',
  MediumTree = 'medium_tree.png',
  FullTree = 'full_tree.png',
}

export interface GetTotalBadgesResponse {
  items: BadgeGained[];
}

export interface GetCurrentBadgeResponse {
  item: CurrentBadge;
}

export interface CurrentBadge {
  __typename: string;
  currentPointsAmount: number;
  companyId: string;
  badge: Badge;
  badgeId: string;
  userId: string;
  updatedAt: string;
  createdAt: string;
  sk: string;
  targetPointsAmount: number;
  pk: string;
}

export interface Badge {
  name: BadgeType;
  progression: ProgressionEntity[];
  createdAt: string;
  id: string;
  updatedAt: string;
}

export interface ProgressionEntity {
  minProgress: number;
  image: string;
}

export interface GetAchievementsRequest {
  companyId: string;
}

export interface GetAchievementsResponse {
  items: Achievement[];
}

export interface GetHistoryChallengesRequest {
  companyId: string;
}

export interface GetChallengesResponse {
  items: Challenge[];
}

export interface GetChallengeRequest {
  companyId: string;
  datetime: string;
}

export interface GetChallengeResponse {
  item: Challenge;
}

export interface Challenge {
  endsAt: string;
  targets: ChallengePrize[];
  companyId: string;
  startsAt: string;
  targetAmount: number;
  currentAmount: number;
  id: string;
}

export enum Medal {
  Gold = '/gold.png',
  Silver = '/silver.png',
  Bronze = '/bronze.png',
}

export interface ChallengePrize {
  icon: Medal;
  prizeQta: number;
  minPercent: number;
  type: string;
  prizeId: string;
  labels: Labels;
  prize: Prize;
}

export type Labels = {
  [K in SupportedLocale]?: string;
};

export interface Prize {
  image: string;
  labels: Labels;
  metrics: Metrics;
  id: string;
  name: string;
  type: string;
}

export interface Metrics {
  [key: string]: Metric;
}

export interface Metric {
  amount: number;
  unit: string;
  labels: Labels;
}

export interface Achievement {
  totAmount: number;
  companyId: string;
  updatedAt: string;
  prizeId: string;
  createdAt: string;
  metrics: Metrics;
  prize: Prize;
}

export interface BadgeGained {
  __typename: string;
  count: number;
  companyId: string;
  badge: Badge;
  badgeId: string;
  updatedAt: string;
  userId: string;
  createdAt: string;
  sk: string;
  pk: string;
}
