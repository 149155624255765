export default {
  timePicker: {
    title: 'Elige un horario',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
  days: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  bottomTab: {
    teams: 'Equipos',
    profile: 'Perfil',
    progress: 'Progresos',
    stats: 'Estadísticas',
    feedback: 'Retroalimentación',
    teamSettings: 'Configuración del equipo',
  },
  authentication: {
    title: 'Hola, ingresa para comenzar!',
    description:
      'Únete a nosotros y comienza a rastrear tu ánimo diario, o úsalo con tu equipo de trabajo!',
    email: 'Correo electrónico',
    acceptTerms1: 'Declaro aceptar ',
    acceptTerms2: ' y ',
    acceptTerms3: ' de Qomprendo',
    termsAndConditions: 'Términos y Condiciones',
    privacyPolicy: 'Política de Privacidad',
    emailError: 'El correo electrónico ingresado no es válido',
    passwordlessExplanation:
      'Te enviaremos un código para verificar tu identidad, ingrésalo y estarás dentro!',
    cta: 'Acceder',
  },
  onboarding: {
    privacy: {
      title: 'Privacidad, términos y condiciones',
      description:
        'Para continuar, es necesario aceptar la política de privacidad y finalmente los términos y condiciones',
      confirm: 'Confirmar',
    },
    name: {
      title: 'Tu nombre',
      description:
        'Ingresa el nombre que será visto por otras personas que usan Qomprendo.',
      name: 'Nombre',
      next: 'Siguiente',
    },
    birthDate: {
      title: 'Tu fecha de nacimiento',
      description:
        'La fecha de nacimiento es importante para entender mejor tu verdadero estado de ánimo',
      birthDate: 'Fecha de nacimiento',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      next: 'Siguiente',
      skip: 'Omitir',
    },
    anonymousVote: {
      title: 'Visibilidad del feedback',
      description1:
        'En Qomprendo valoramos mucho tu privacidad, por eso el feedback es anónimo por defecto.',
      description3:
        'Esta configuración siempre está disponible en tu perfil y puedes cambiarla cuando quieras.',
      switchLabel: 'Habilitar feedback nominativo',
      skip: 'Omitir',
      finish: 'Finalizar',
    },
  },
  intro: {
    welcome: '¡Bienvenido!',
    feedbackTitle: 'El feedback que necesitabas',
    feedbackDescription:
      'Únete al desafío humano acumulando tantos puntos como puedas, ayuda a tu empresa a alcanzar todos los objetivos y ¡ten la oportunidad de ganar un premio!',
    historyTitle: 'Recuerda las calificaciones que diste',
    statsDescription:
      'Sigue tu estado de ánimo en cada equipo y entenderás de inmediato qué aspectos están mejorando y cuáles necesitan mejora!',
    gamificationTitle: 'Gana puntos y llega primero',
    rewardsDescription:
      'Escala la clasificación de la empresa y llega primero cada mes, ayudando a ti mismo y al medio ambiente con nuestras especiales recompensas verdes.',
    enter: 'Acceder',
  },
  authCode: {
    title: 'Introduce el código',
    description: 'Introduce el código que te hemos enviado a %{email}.',
    resendCode: 'Reenviar el código',
    confirm: 'Confirmar',
    pasteClipboard: 'Pegar código del portapapeles',
  },
  teamSettings: {
    roleDescription1: 'Eres',
    roleDescription2: 'del equipo.',
    administrator: 'administrador',
    teamMembers: 'Miembros',
    yourNotifications: 'Notificaciones',
    teamNotifications: 'Notificaciones estándar del equipo',
    enable: 'Habilitar',
    personalizeReminder: 'Personalizar las notificaciones',
    emptyDescription: 'Aún no hay ningún equipo para gestionar.',
    at: 'A las:',
  },
  teamMembers: {
    search: 'Buscar...',
    title: 'Miembros del equipo',
  },
  manageUser: {
    title: 'Gestionar usuario',
    handledUser: 'Estás gestionando:',
    notifications: 'Notificaciones',
    enable: 'Habilitar',
    personalizeReminder: 'Personalizar las notificaciones',
    at: 'A las:',
    removeFromTeam: 'Eliminar del equipo',
  },
  userNotification: {
    title: 'Notificaciones',
    enable: 'Habilitar',
    personalizeReminder: 'Personalizar las notificaciones',
    at: 'A las:',
    save: 'Guardar',
    cancel: 'Cancelar',
  },
  profile: {
    title: 'Perfil',
    details: 'Detalles',
    changeImage: 'Cambiar imagen de perfil',
    dateOfBirth: 'Fecha de nacimiento',
    username: 'Nombre de usuario',
    email: 'Correo electrónico',
    signOut: 'Cerrar sesión',
    requestDelete: 'Solicitar eliminación',
    editProfileImage: 'Cambiar imagen de perfil',
    settings: 'Configuraciones',
    anonymousVoteLabel: 'Habilitar feedback anónimo',
    harmoniaActiveLabel: 'Habilitar consejos de Harmonia',
    deleteModal: {
      title: '¿Confirmas la eliminación?',
      description:
        'Tu solicitud será procesada y tu cuenta será eliminada en un máximo de 14 días desde la solicitud. Una vez eliminada la cuenta, no podrás acceder y tendrás que registrarte nuevamente para interactuar con la aplicación. ¿Estás seguro de que quieres eliminar tu perfil?',
      cancel: 'Cancelar',
      delete: 'Eliminar',
    },
    confirmModal: {
      title: 'Eliminación solicitada',
      description:
        'Lamentamos verte partir... Dentro de 14 días tu perfil será eliminado y no será posible el acceso a tu área privada. Agradecemos que nos hayas dado una oportunidad y esperamos, en el futuro, volver a verte!',
      ok: 'Ok',
    },
  },
  editProfile: {
    title: 'Editar perfil',
    description:
      'En esta sección puedes modificar tu perfil, por el momento solo el nombre de usuario puede ser modificado.',
    usernamePlaceholder: 'Nombre de usuario',
    usernameError: 'El nombre de usuario debe contener al menos 3 caracteres',
    save: 'Guardar perfil',
  },
  gamification: {
    feedbackModal: {
      title: '¡Felicidades!',
      descriptionNotCompleted:
        '¡Tu árbol digital ha crecido! Continúa así y se hará grande en un abrir y cerrar de ojos!',
      descriptionCompleted:
        '¡Fantástico! Tu árbol digital se ha hecho grande gracias a ti, desde mañana tendrás otro para crecer. ¡Sigue así!',
      close: 'Cerrar',
    },
    myBadge: 'Mis insignias',
    section: 'Sección',
    personal: 'Personal',
    company: 'Empresa',
    companySection: {
      allProgresses: 'Logros',
      daysLeft: 'Días restantes',
      challengeExpired: 'Desafío terminado',
      nextPrice: 'Próximo premio: %{currentPoints} / %{targetPoints}',
      prizes: 'Premios',
      results: 'Resultados hasta ahora',
      unlockAllPrizes:
        'Tu empresa ha desbloqueado todos los premios, ¡felicidades!',
      quantity: 'Cantidad',
      noData: 'En este momento no hay un desafío presente en tu empresa.',
    },
  },
  leaderboard: {
    emptyMessage:
      'Aquí solo hay un gato feliz, pero aún nadie en la clasificación.',
    yourPosition: 'Tu posición',
    leaderboard: 'Clasificación',
    firstArrived:
      '🥇🏆 ¡Felicidades! ¡Este mes llegaste primero! Sigue así el próximo mes.',
    secondArrived:
      '🥈🏆 ¡Felicidades! Llegaste segundo este mes. ¡Estuviste muy cerca de la primera posición!',
    thirdArrived:
      '🥉🏆 ¡Felicidades! Llegaste tercero este mes. ¡Estuviste muy cerca de la primera posición!',
    parteciped:
      '¡Gracias por participar en la competición! Continúa cuidando tu bienestar en la empresa.',
    helpBottomSheet: {
      title: '¿Cómo funciona?',
      pointDescription:
        'Por cada feedback dejado en un equipo se asignan puntos, el total de puntos se actualiza al final del día. Con un feedback obtienes 100 puntos, añadiendo un comentario obtienes 150.',
      rewardDescription:
        'Si al final del mes al menos el 80% de los feedbacks empresariales han sido dados, Qomprendo se comprometerá a plantar árboles en su nombre.',
      close: 'Cerrar',
    },
  },
  stats: {
    summary: 'Resumen',
    daysInRow: 'Constancia',
    humorChart: 'Gráfico de humor',
    moodsCount: 'Conteo de estados de ánimo',
    commentSentiment: 'Análisis de comentarios',
    moodCalendar: 'Tu calendario de humor',
    wellnessIndex: 'Índice de bienestar',
    feedbackCalendar: 'Tu calendario de feedbacks',
    streak: 'Racha semanal más larga: ',
    forgetFeedback1: 'Te has olvidado', // TODO: Remover
    forgetFeedback2: 'veces de dar feedback esta semana', // TODO: Remover
    feedbackCountDescription1:
      'Hola %{name}, has dado %{feedbackCount} feedbacks en los últimos 14 días,',
    feedbackCountDescription2: {
      lowerThanTwentyPercent:
        'debe ser un periodo intenso, cuida de tu bienestar, usar Qomprendo puede hacer la diferencia.',
      fromTwentyToForty: 'cuéntame cómo fue tu día hoy.',
      fromFortyToSixty:
        'has empezado con el pie derecho, dime más a menudo cómo te sientes.',
      fromSixtyToEighty:
        '¡buen trabajo! Estás haciendo una diferencia para tu bienestar.',
      fromEightyToHundred: 'Estás cerca de la semana perfecta, sigue así.',
      hundred: '¡WOW! Has alcanzado la semana perfecta, sigue así.',
    },
    emptySentiment:
      'No tenemos suficientes datos para entender el sentimiento predominante de la semana.',
    rememberComment:
      'Recuerda siempre comentar, para mantener esta sección siempre habilitada.',
    sentimentsComments:
      'Los sentimientos que han emergido de tus comentarios son predominantemente:',
    positive: 'Positivos',
    negative: 'Críticos',
    neutral: 'Neutrales',
    mixed: 'Contradictorios',
    warning: 'Aviso',
    sentimentWarning1: 'Esta semana solo has dejado un comentario el',
    sentimentWarning2: 'de las veces.',
    sentimentWarning3:
      'Protege tu bienestar, cuéntame cómo te sientes más a menudo.',
    feelGood: 'Aspectos positivos',
    feelGoodDescription:
      'Estas son las áreas que están contribuyendo a tu bienestar laboral',
    feelBad: 'Aspectos a mejorar',
    feelBadDescription:
      'Estas son las áreas a mejorar para tu bienestar laboral',
    notEnoughtData1:
      'No tenemos suficientes datos para proporcionarte una estadística precisa de la semana.',
    notEnoughtData2: 'Vuelve cuando hayas dado más feedback.',
    feedbackDetails: {
      teamLabel: 'Equipo al que has dado feedback',
    },
  },
  changeTeamBottomSheet: {
    title: 'Selecciona un equipo',
    others: 'O puedes ver:',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    summary: 'Resumen',
  },
  changeCompanyBottomSheet: {
    title: 'Selecciona una empresa',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    summary: 'Resumen',
  },
  feedback: {
    title: '¿Cómo te fue hoy?',
    alreadyVoted: 'Equipo sobre el que ya has dado tu feedback',
    anonymous: 'Visibilidad: Anónimo',
    named: 'Visibilidad: Nominal',
    leaveComment: 'Deja un comentario',
    notEnoughtData:
      'No puedes responder porque aún no formas parte de un equipo.',
    commentPlaceholder: 'Describe tu día en pocas palabras...',
    feedbackSelectionTypeTitle: '¿Qué aspectos han influido en tu día?',
    goodAreasFeedback: '¿Qué aspectos han ido bien?',
    badAreasFeedback: '¿Qué aspectos se pueden mejorar?',
    feedbackVisibility: 'Visibilidad del feedback',
    bottomBar: {
      points: 'Puntos:',
      on: 'sobre',
      finish: 'Hecho',
    },
  },
  harmonia: {
    emptyMessage:
      'Hola, soy Harmonia, ¿cómo fue tu día? Hablemos juntos, puedo ayudarte a mejorarlo.',
    notViewed: 'Nuevos mensajes',
    inputPlaceholder: 'Escribe un mensaje...',
    message1: 'Estoy tratando de recordar cómo te llamas...',
    message2: 'Estoy interpretando tus comentarios...',
    message3: 'Estoy pensando en la mejor respuesta para darte...',
    message4: 'No sé, quizás así es mejor...',
    message5: 'Dame solo un momento más...',
  },
  slack: {
    requestScreen: {
      title: 'Agrega Qomprendo a tu espacio de trabajo en Slack',
      paragraph:
        'Continúa para autorizar a Qomprendo a comunicarse contigo a través de Slack',
      cta: 'Continuar con Slack',
    },
    errorFallback: {
      title: 'Se ha producido un error',
      cta: 'Intenta de nuevo',
    },
    successFallback: {
      title:
        '¡Felicidades! Acabas de agregar Qomprendo a tu área de trabajo en Slack',
      cta: 'Volver a la página de inicio',
    },
  },
  allChallenges: {
    allResults: 'Todos los resultados obtenidos',
    previousChallenges: 'Las competiciones pasadas',
    range: 'Periodo',
    prize: 'Premio',
    quantity: 'Cantidad',
  },
  shared: {
    selectAppLanguage: {
      label: 'Idioma seleccionado',
      modalTitle: 'Cambiar idioma',
      modalDescription:
        'Selecciona el idioma que deseas utilizar en la aplicación',
      save: 'Guardar',
      cancel: 'Cancelar',
    },
    languages: {
      it: 'Italiano',
      en: 'English',
      de: 'Deutsch',
      es: 'Español',
    },
  },
  remoteConfig: {
    versionControl: {
      title: 'Actualización disponible',
      description:
        'Una nueva versión de Qomprendo está disponible, actualiza para continuar usando la app.',
      update: 'Actualizar',
    },
    genericError: {
      title: 'La aplicación no está disponible en este momento',
      message:
        'Parece que hay problemas con el servidor, nos disculpamos por los inconvenientes, intenta más tarde',
    },
    networkError: {
      title: 'Hay problemas con tu red',
      description:
        'Hemos detectado problemas con tu red de internet y esto te impide utilizar Qomprendo, intenta más tarde o cuando tengas una conexión más estable',
      ctaLabel: 'Intenta de nuevo',
    },
  },
};
