import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { IErrorHandler } from '../ErrorHandler/IErrorHandler';
import {
  DeleteRequestConfig,
  GetRequestConfig,
  IHttpClient,
  PatchRequestConfig,
  PostRequestConfig,
  PutRequestConfig,
} from './IHttpClient';
import { Config } from '../../config';
import axiosRetry, { exponentialDelay, isNetworkError } from 'axios-retry';
import { recordError } from '../../portability/services/Firebase/Crashlytics/Crashlytics';

export type HttpClientInitParams = {
  errorHandler: IErrorHandler;
  axiosInstance?: AxiosInstance;
};

export class CognitoHttpClient implements IHttpClient {
  private readonly axiosInstance: AxiosInstance;
  private readonly cancelToken: CancelTokenSource;
  private errorInterceptor: null | number = null;

  constructor({ axiosInstance, errorHandler }: HttpClientInitParams) {
    this.cancelToken = axios.CancelToken.source();
    this.axiosInstance =
      axiosInstance ??
      axios.create({
        baseURL: `https://cognito-idp.${Config.congnitoRegion}.amazonaws.com/`,
        cancelToken: this.cancelToken.token,
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
        },
        timeout: 10000,
      });

    axiosRetry(this.axiosInstance, {
      retries: 3,
      retryDelay: exponentialDelay,
      retryCondition: isNetworkError,
    });

    this.setupErrorHandler(errorHandler);
  }

  public get = async <T>(
    url: string,
    configs?: GetRequestConfig
  ): Promise<T> => {
    const res = await this.axiosInstance.get<T>(url, {
      params: configs?.params,
      headers: configs?.headers,
    });
    return res?.data;
  };

  public post = async <T, P>(
    url: string,
    configs?: PostRequestConfig<P>
  ): Promise<T> => {
    const res = await this.axiosInstance.post<T>(url, configs?.body, {
      headers: configs?.headers,
    });
    return res?.data;
  };

  public put = async <T, P>(
    url: string,
    configs: PutRequestConfig<P> = { isAuthorized: true }
  ): Promise<T> => {
    const res = await this.axiosInstance.put<T>(url, configs?.body, {
      headers: configs?.headers,
      data: configs?.data,
    });

    return res?.data;
  };

  public patch = async <T, P>(
    url: string,
    configs?: PatchRequestConfig<P>
  ): Promise<T> => {
    const res = await this.axiosInstance.patch<T>(url, configs?.body, {
      headers: configs?.headers,
    });

    return res?.data;
  };

  public delete = async <T>(
    url: string,
    configs?: DeleteRequestConfig
  ): Promise<T> => {
    const res = await this.axiosInstance.delete<T>(url, {
      params: configs?.params,
      headers: configs?.headers,
    });

    return res?.data;
  };

  private setupErrorHandler = (errorHandler: IErrorHandler) => {
    this.errorInterceptor = this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (e) => {
        if (e?.response?.data?.Error) {
          errorHandler.blockInteraction(e.response.data.Error);
        } else if (!e?.status && !e?.response) {
          errorHandler.blockInteraction(
            'Network error! Retry when connection is stable'
          );
        } else {
          recordError(e);
          errorHandler.blockInteraction('Something gone wrong!');
        }
        throw e;
      }
    );
  };

  public readonly cancelRequests = () => {
    this.cancelToken.cancel();
  };
}
