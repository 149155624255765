import React, { useEffect, useState } from 'react';
import { Layout } from '../../components';
import { useRoute } from '@react-navigation/native';
import { useSubscribeSlack } from '../../hooks/api/slack.api';
import useUserData from '../../hooks/useUserData';
import SlackRequestAccess from './components/SlackRequestAccess';
import { useFullPageLoader } from '../../context/Loader/useFullPageLoader';
import SlackErrorFallback from './components/SlackErrorFallback';
import SlackSuccessFallback from './components/SlackSuccessFallback';

const SlackIntegrationScreen = () => {
  const { params } = useRoute();
  const { company, isReady } = useUserData(true);
  const { code } = (params ?? {}) as { code?: string };
  const [isLoading, setIsLoading] = useState(false);
  const [openLoader, closeLoader] = useFullPageLoader();
  const {
    mutateAsync: subscribeToSlack,
    isPending,
    isSuccess,
    isError,
    error,
  } = useSubscribeSlack();

  useEffect(() => {
    const slackSubCall = async () => {
      if (code && isReady) {
        try {
          openLoader();
          setIsLoading(true);
          await subscribeToSlack({ code, companyId: company?.id ?? '' });
        } finally {
          setIsLoading(false);
          closeLoader();
        }
      }
    };

    slackSubCall();
  }, [code, isReady, company, subscribeToSlack, openLoader, closeLoader]);

  return (
    <Layout>
      {(!code || isPending) && <SlackRequestAccess isLoading={isLoading} />}
      {isSuccess && <SlackSuccessFallback />}
      {isError && <SlackErrorFallback error={error?.toString() ?? ''} />}
    </Layout>
  );
};

export default SlackIntegrationScreen;
