import * as React from 'react';
import { UmModal } from '../UmModal';
import { useLanguage } from '../../locale';
import { SupportedLocale } from '../../locale/localizationContext';
import { s } from 'react-native-wind';
import { Description } from '../Description';
import { Subtitle } from '../Subtitle';
import { SelectableElement } from '../SelectebleElement';
import { View } from 'react-native';
import { Button, ButtonType } from '../Button';

type SelectLanguageModalProps = {
  isOpen?: boolean;
  initLang: SupportedLocale;
  onLangSelect: (lang: SupportedLocale) => void;
  close: () => void;
};

const SelectLanguageModal: React.FC<SelectLanguageModalProps> = ({
  isOpen = false,
  initLang,
  onLangSelect,
  close,
}) => {
  const [t] = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = React.useState(initLang);

  React.useEffect(() => {
    setSelectedLanguage(initLang);
  }, [initLang]);

  const selectItalian = React.useCallback(() => {
    setSelectedLanguage(SupportedLocale.It);
  }, []);

  const selectEnglish = React.useCallback(() => {
    setSelectedLanguage(SupportedLocale.En);
  }, []);

  const selectGerman = React.useCallback(() => {
    setSelectedLanguage(SupportedLocale.De);
  }, []);

  const selectSpanish = React.useCallback(() => {
    setSelectedLanguage(SupportedLocale.Es);
  }, []);

  const onClose = React.useCallback(() => {
    setSelectedLanguage(initLang);
    close();
  }, [initLang, close]);

  const onSave = React.useCallback(() => {
    onLangSelect(selectedLanguage);
    onClose();
  }, [onClose, onLangSelect, selectedLanguage]);

  return (
    <UmModal isOpen={isOpen}>
      <Subtitle>{t('shared.selectAppLanguage.modalTitle')}</Subtitle>
      <Description style={s`mt-2`}>
        {t('shared.selectAppLanguage.modalDescription')}
      </Description>
      <SelectableElement
        style={s`mt-8`}
        isSelected={selectedLanguage === SupportedLocale.En}
        lable={t('shared.languages.en')}
        onPress={selectEnglish}
      />
      <SelectableElement
        style={s`mt-3`}
        isSelected={selectedLanguage === SupportedLocale.It}
        lable={t('shared.languages.it')}
        onPress={selectItalian}
      />
      <SelectableElement
        style={s`mt-3`}
        isSelected={selectedLanguage === SupportedLocale.De}
        lable={t('shared.languages.de')}
        onPress={selectGerman}
      />
      <SelectableElement
        style={s`mt-3`}
        isSelected={selectedLanguage === SupportedLocale.Es}
        lable={t('shared.languages.es')}
        onPress={selectSpanish}
      />
      <View style={s`flex-row mt-8`}>
        <Button
          buttonType={ButtonType.Tertiary}
          onPress={onClose}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('shared.selectAppLanguage.cancel')}
        </Button>
        <Button
          onPress={onSave}
          buttonType={ButtonType.Primary}
          containerStyle={s`flex-1`}
        >
          {t('shared.selectAppLanguage.save')}
        </Button>
      </View>
    </UmModal>
  );
};

export default SelectLanguageModal;
